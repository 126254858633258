import { useMemo } from "react";

import useResizeWindow from "hooks/use-resize-window";

import { SIZE } from "constants/styles";

const useWindowSizes = () => {
  const { sizes } = useResizeWindow();

  return useMemo(
    () => ({
      isMobileWidth: SIZE.getIsMobileWidth(sizes),
      isMobileLargeWidth: SIZE.getIsMobileLargeWidth(sizes),
      isMobileMediumWidth: SIZE.getIsMobileMediumWidth(sizes),
      isMobileSmallWidth: SIZE.getIsMobileSmallWidth(sizes),
      isTabletSmallWidth: SIZE.getIsTabletSmallWidth(sizes),
      isTabletWidth: SIZE.getIsTabletWidth(sizes),
      isTabletLargeWidth: SIZE.getIsTabletLargeWidth(sizes),
      isDesktopSmallWidth: SIZE.getIsDesktopSmallWidth(sizes),
      isDesktopMediumWidth: SIZE.getIsDesktopMediumWidth(sizes),
      isDesktopLargeWidth: SIZE.getIsDesktopLargeWidth(sizes),
      isDesktopWidth: SIZE.getIsDesktopWidth(sizes),
    }),
    [sizes],
  );
};

export default useWindowSizes;
